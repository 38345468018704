import ScrollHint from "scroll-hint";

(function ($) {
	/* Check Window Width */
	var resizeTimeout = null;
	var cachedWindowWidth = $(window).width();

	// Check if window width has actually changed
	function checkWindowWidth() {
		var newWindowWidth = $(window).width();

		if (newWindowWidth !== cachedWindowWidth) {
			// If changed call functions on resize

			cachedWindowWidth = newWindowWidth;
		}
	}
	$(window).on("resize", function () {
		if (resizeTimeout !== null) {
			clearTimeout(resizeTimeout);
		}

		resizeTimeout = setTimeout(checkWindowWidth, 50);
	});

	/* Help Center */
	function setupHelpCenter() {
		// Expand/collapse sidebar category widget (used on mobile)
		var sidebarCatsToggle = $("#sidebar .toggle-categories");
		var sidebarCatWidget = $("#sidebar .top-level-categories");

		// Toggle categories; change toggle icon
		sidebarCatsToggle.click(function () {
			if ($(this).next(".top-level-categories").hasClass("hide")) {
				$(this).next(".top-level-categories").removeClass("hide");
				$(this).find("i").removeClass("fa-plus");
				$(this).find("i").addClass("fa-minus");
			} else {
				$(this).next(".top-level-categories").addClass("hide");
				$(this).find("i").removeClass("fa-minus");
				$(this).find("i").addClass("fa-plus");
			}
		});

		// Autohide on mobile
		if (cachedWindowWidth <= 767 && !sidebarCatWidget.hasClass("hide")) {
			sidebarCatWidget.addClass("hide");
			sidebarCatsToggle.find("i").removeClass("fa-minus");
			sidebarCatsToggle.find("i").addClass("fa-plus");
		}

		// Expand/collapse sidebar categories
		var sidebarTopLevelCatsToggle = $(
			"#sidebar .top-level-categories > .category .toggle-child-categories"
		);

		// Toggle child categories; change toggle icon
		sidebarTopLevelCatsToggle.click(function () {
			if ($(this).closest(".category").hasClass("expanded")) {
				$(this).closest(".category").removeClass("expanded");
				$(this).find("i").removeClass("fa-chevron-up");
				$(this).find("i").addClass("fa-chevron-down");
			} else {
				$(this).closest(".category").addClass("expanded");
				$(this).find("i").removeClass("fa-chevron-down");
				$(this).find("i").addClass("fa-chevron-up");
			}
		});

		// Add current-cat class to child categories (use anchor in URL)
		function addCurrentClassToChildCategory() {
			var currentAnchor = window.location.hash.substr(1);
			var singleBreadcrumbDataSlug = $(".breadcrumbs [data-slug]").attr(
				"data-slug"
			);

			// If there is an anchor
			if (currentAnchor !== "") {
				$('#sidebar .child-category a[href$="' + currentAnchor + '"]')
					.closest(".child-category")
					.addClass("current-cat");
			}

			// If data-slug exists, for single articles
			if (singleBreadcrumbDataSlug !== "") {
				$(
					'#sidebar .child-category a[href$="' +
						singleBreadcrumbDataSlug +
						'"]'
				)
					.closest(".child-category")
					.addClass("current-cat");
			}
		}
		addCurrentClassToChildCategory();

		// Expand category if child category within has current-cat class
		function expandParentCategory() {
			var currentSelectedTopLevelCategory = $(
				".top-level-categories > .category.current-cat"
			);

			if (
				currentSelectedTopLevelCategory.find(
					".child-category.current-cat"
				)
			) {
				currentSelectedTopLevelCategory.addClass("expanded");
				currentSelectedTopLevelCategory
					.find("i")
					.removeClass("fa-chevron-down");
				currentSelectedTopLevelCategory
					.find("i")
					.addClass("fa-chevron-up");
			}
		}
		expandParentCategory();

		// On hash change adjust current-cat class as necessary
		$(window).on("hashchange", function () {
			// Remove all current-cat classes from child categories
			$(".child-categories .child-category").removeClass("current-cat");

			addCurrentClassToChildCategory();
		});

		// Remove top-level article container if empty
		var topLevelArticleContainer = $(".article-container.top-level");

		if (topLevelArticleContainer.children().length === 0) {
			topLevelArticleContainer.remove();
		}

		// Expand/collapse article lists (if more than 5 articles in a category
		var moreLessArticlesToggle = $(".toggle-more-articles");

		// Toggle articles; change text
		moreLessArticlesToggle.click(function () {
			if ($(this).parent().hasClass("expanded")) {
				$(this).parent().removeClass("expanded");
				$(this).html('Show More <i class="far fa-chevron-down"></i>');
			} else {
				$(this).parent().addClass("expanded");
				$(this).html('Show Less <i class="far fa-chevron-up"></i>');
			}
		});
	}
	if ($(".help-center").length) {
		setupHelpCenter();
	}

	function loanPluginUpdates() {
		// Add simple script to remove '?' from calculator labels
		setTimeout(function () {
			var calculatorLabels = $("form.financial-calculators .label");

			calculatorLabels.each(function () {
				$(this).text($(this).text().replace("?", ""));
			});
		}, 100);
	}
	if ($("#autoloan-plugin").length || $("#loan-plugin").length) {
		loanPluginUpdates();
	}

	// External link disclaimer modal
	function externalLinkDisclaimerModal() {
		function init() {
			const externalLinkDisclaimerModal = dialogEventListeners({
				open: document.querySelectorAll(".external-link-disclaimer"),
				dialog: document.querySelector(
					".external-link-disclaimer-modal"
				),
				close: document.querySelector(
					".external-link-disclaimer-modal .close"
				),
				yes: document.querySelector(
					".external-link-disclaimer-modal .yes.button"
				),
				no: document.querySelector(
					".external-link-disclaimer-modal .no.button"
				)
			});
		}
		init();

		// Open dialog
		function openDialog(event, dialog, yesButton) {
			let link = "";
			event.preventDefault();
			dialog.showModal();

			// Set "Yes" button href to href of clicked element
			// Handle links that contain elements like icons
			if (undefined === event.target.href) {
				link = event.target.closest("a").href;
			} else {
				link = event.target.href;
			}
			// console.log(link);
			yesButton.setAttribute("href", link);
		}

		// Close dialog
		function closeDialog(dialog) {
			dialog.close();
		}

		// Dialog add event listeners
		function dialogEventListeners(args) {
			// Open
			args.open.forEach((el) => {
				el.addEventListener("click", (event) => {
					openDialog(event, args.dialog, args.yes);
				});
			});

			// Scroll
			window.addEventListener("scroll", () => {
				document.documentElement.style.setProperty(
					"--scroll-y",
					`${window.scrollY}px`
				);
			});

			// Close
			// On ::backdrop click
			args.dialog.addEventListener("click", (event) => {
				if (event.target.nodeName === "DIALOG") {
					closeDialog(args.dialog);
				}
			});

			// On close button click
			args.close.addEventListener("click", (event) => {
				closeDialog(args.dialog);
			});

			// On no button click
			args.no.addEventListener("click", (event) => {
				closeDialog(args.dialog);
			});

			// On yes button click
		}
	}
	externalLinkDisclaimerModal();

	// Apply modal
	function applyModal() {
		const applyModalLinks = document.querySelectorAll(".apply-modal-link");

		if (applyModalLinks.length === 0) {
			return;
		}

		function init() {
			const applyModal = dialogEventListeners({
				open: applyModalLinks,
				dialog: document.querySelector(".apply-modal"),
				close: document.querySelector(".apply-modal .close")
			});
		}
		init();

		// Open dialog
		function openDialog(event, dialog) {
			event.preventDefault();
			dialog.showModal();
		}

		// Close dialog
		function closeDialog(dialog) {
			dialog.close();
		}

		// Dialog add event listeners
		function dialogEventListeners(args) {
			// Open
			args.open.forEach((el) => {
				el.addEventListener("click", (event) => {
					openDialog(event, args.dialog);
				});
			});

			// Scroll
			window.addEventListener("scroll", () => {
				document.documentElement.style.setProperty(
					"--scroll-y",
					`${window.scrollY}px`
				);
			});

			// Close
			// On ::backdrop click
			args.dialog.addEventListener("click", (event) => {
				if (event.target.nodeName === "DIALOG") {
					closeDialog(args.dialog);
				}
			});

			// On close button click
			args.close.addEventListener("click", (event) => {
				closeDialog(args.dialog);
			});
		}
	}
	applyModal();

	// Page navigation block
	const pageNavigationLinks = document.querySelectorAll(
		".page-navigation-block .page-links .link"
	);

	function pageNavigationBlock() {
		pageNavigationLinks.forEach((link) => {
			const button = link.querySelector("button");
			const anchorID = button
				.getAttribute("data-target")
				.replace(/[^a-zA-Z0-9-]/g, "")
				.replace(/--/g, "-");
			const target = document.getElementById(anchorID);

			button.addEventListener("click", () => {
				if (target) {
					target.scrollIntoView("smooth");
				} else {
					console.log("Target not found.");
				}
			});
		});
	}

	if (pageNavigationLinks.length) {
		pageNavigationBlock();
	}

	// Scroll hint
	function scrollHint() {
		new ScrollHint(".js-scrollable", {
			applyToParents: true,
			i18n: {
				scrollable: "Scrollable"
			},
			suggestiveShadow: true
		});
	}
	scrollHint();
})(jQuery);
